import axios from 'axios';
import * as userSessionActions from '../redux/actions/user-session';
import { useHistory } from "react-router-dom";
import { PapiroConsole } from '../utils/papiroConsole';
import { env } from '../env'

//PapiroConsole.log("dev env")

let saasError = false
try {
  const urlSaaS = process.env.REACT_APP_API+"/hirerconfig/geturl/"+window.location.host+"/1";
  
  var xhttp = new XMLHttpRequest();
  xhttp.open("GET", urlSaaS, false);
  xhttp.send();
} catch(e) {
  if(e.name == "NetworkError") {
    PapiroConsole.log("entrou NetworkError")
    if(e.message.indexOf("Failed to execute 'send' on 'XMLHttpRequest'") !== -1) {
      saasError = true;
      PapiroConsole.log("entrou Failed 'send' 'XMLHttpRequest'")
    }else if(e.message.indexOf("A network error occurred.") !== -1) {
      saasError = true;
      PapiroConsole.log("entrou em A network error occurred.")
    }else {
      PapiroConsole.log("Outro erro")
      PapiroConsole.log(e.name)
      PapiroConsole.log(e.message)
    }
  }
}

function GetCurrentTimeStringToFloat() {
	var currentdate = new Date(); 
	var time = currentdate.getHours() + ""  
					+ currentdate.getMinutes() + "" 
					+ currentdate.getSeconds();
    //alert(time);
	var hoursMinutes = time.split(/[.:]/);
	var hours = parseInt(hoursMinutes[0], 10);
	var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    //alert((hours + minutes / 60));

	return time;//hours + minutes / 60;
}

let Api = {}

let baseSassHirerId = 0
let baseURL = null
let baseURLBack = null
let baseURLCone = null
let baseURLApiSignalrHub = null
const MessengerHubUrl = env.REACT_APP_MEXX_MESSENGER_HUB_URL;

let baseURLFrontCalledService = ''
async function refreshToken(token, cb) {
    const refreshingCall = await Api.post('/users/refresh-token', { token }).then(({ data }) => {
		localStorage.removeItem('accesstoken');
    localStorage.removeItem('accesstokenreports');
    localStorage.removeItem('accesstoken2talk');
  if(data.success){
			localStorage.setItem('accesstoken', data.data.accesstoken);
		}
        return Promise.resolve(data);
    }).then(cb);
    return refreshingCall;
}

let acceptLanguage = 'pt-BR'
let language = window.location.pathname.split('/')[1]

if (language === 'en')
  acceptLanguage = 'en-US'
else if (language === 'es')
  acceptLanguage = 'es-ES'

if(!saasError) {
  let baseUrlResponse = JSON.parse(xhttp.responseText);
  if (baseUrlResponse && baseUrlResponse.data && baseUrlResponse.data.urlfrontcubecalledservice) {
    baseURLFrontCalledService = `https://${baseUrlResponse.data.urlfrontcubecalledservice}/`
  }
  if (baseUrlResponse && baseUrlResponse.data && baseUrlResponse.data.hirerid && baseUrlResponse.data.hirerid > 0)
    baseSassHirerId = baseUrlResponse.data.hirerid
  if(baseUrlResponse && baseUrlResponse.data && baseUrlResponse.data.urlapicube) {
    baseURL = "https://"+baseUrlResponse.data.urlapicube+"/api"; 
    baseURLBack = "https://"+baseUrlResponse.data.urlapicube+"/";


    if(baseUrlResponse.data.urlapicone){
      baseURLCone = "https://"+baseUrlResponse.data.urlapicone+"/";    }
    else{
      if(baseUrlResponse.data.urlapi2talkservice){
        baseURLCone = "https://"+baseUrlResponse.data.urlapi2talkservice+"/";
      }
    }
    if(baseUrlResponse.data.config && baseUrlResponse.data.config.urlapisignalrhub){
      baseURLApiSignalrHub = "https://"+baseUrlResponse.data.config.urlapisignalrhub+"/";
    }
    
    // CHUMBAR A VARIAVEL da API DE HUB QUE VEM DO SAAS SE EXISTIR VARIAVEL DE AMBIENTE NO .ENV
    if(MessengerHubUrl!= null){
      baseURLApiSignalrHub=MessengerHubUrl;
    }

    const urlfront = window.location.host;
    let token = localStorage.getItem('accesstoken');

    Api = axios.create({
      baseURL: baseURL,
    });

    Api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    Api.defaults.headers.post['Accept'] = 'application/json';
    Api.defaults.headers.post['Content-Type'] = 'application/json';
    Api.defaults.headers.common['Accept-Language'] = acceptLanguage

    let configData = null
    let whiteLabelError = false
    
    try {
      PapiroConsole.log(`=== ${baseURL}/whitelabel/systemtheme ===`)
      let xhttpConfig = new XMLHttpRequest();
      xhttpConfig.open("GET", `${baseURL}/whitelabel/systemtheme`, false);
      xhttpConfig.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhttpConfig.send();
      configData = JSON.parse(xhttpConfig.responseText);
    } catch (e) {
      if(e.name == "NetworkError") {
        PapiroConsole.log("entrou NetworkError")
        if(e.message.indexOf("Failed to execute 'send' on 'XMLHttpRequest'") !== -1) {
          whiteLabelError = true;
          PapiroConsole.log("entrou Failed 'send' 'XMLHttpRequest'")
        }else if(e.message.indexOf("A network error occurred.") !== -1) {
          whiteLabelError = true;
          PapiroConsole.log("entrou em A network error occurred.")
        }else {
          PapiroConsole.log("Outro erro")
          PapiroConsole.log(e.name)
          PapiroConsole.log(e.message)
        }
      }
    }
    if (!whiteLabelError) {
      let primarycolorTheme = "#3F51B4";
      let secondarycolorTheme = "#277afe"
      let appName = "CMTECH - Mexx 2DO";
      let footerText = "CMTECH - Mexx 2DO";
      let loginImage = null;
      let menuImage = null;
      let externalUserRegisterImage = null;
      let loginTitle = "Atendimento Inteligente ao Cliente";
      let showfooterusetermslink = true;

      if(configData && configData.data && configData.data.id) {
        if(configData.data.primarycolortheme && configData.data.primarycolortheme != null && configData.data.primarycolortheme != "")
          primarycolorTheme = configData.data.primarycolortheme;
        if(configData.data.secondarycolortheme && configData.data.secondarycolortheme != null && configData.data.secondarycolortheme != "")
          secondarycolorTheme = configData.data.secondarycolortheme;
        if(configData.data.footertext && configData.data.footertext != null && configData.data.footertext != "")
          footerText = configData.data.footertext
        if(configData.data.applicationname && configData.data.applicationname != null && configData.data.applicationname != "")
          appName = configData.data.applicationname
        if(configData.data.logintitle && configData.data.logintitle != null && configData.data.logintitle != "")
          loginTitle = configData.data.logintitle
        if(configData.data.showfooterusetermslink != null)
          showfooterusetermslink = configData.data.showfooterusetermslink
        if(configData.data.loginimage && configData.data.loginimage.anonymoustoken && configData.data.loginimage.anonymoustoken.length > 0) {
          if (configData.data.loginimage && configData.data.loginimage.filerepositorymethod && configData.data.loginimage.filerepositorymethod == 2) {
            let xhttpLoginImageConfig = new XMLHttpRequest();
            xhttpLoginImageConfig.open("GET", `${configData.data.loginimage.anonymoustoken}`, false);
            xhttpLoginImageConfig.setRequestHeader('Access-Control-Allow-Origin', '*');
			try {
				xhttpLoginImageConfig.send();
				let loginImageData = JSON.parse(xhttpLoginImageConfig.responseText);
				if(loginImageData && loginImageData.data && loginImageData.data.length > 0){
				loginImage = loginImageData.data;
				}
			}
			catch(e){

			}


          } else{
            loginImage = configData.data.loginimage.anonymoustoken
		  }
        }
        if(configData.data.menuimage && configData.data.menuimage.anonymoustoken && configData.data.menuimage.anonymoustoken.length > 0) {
          if (configData.data.menuimage && configData.data.menuimage.filerepositorymethod && configData.data.menuimage.filerepositorymethod == 2) {
            let xhttpMenuImageConfig = new XMLHttpRequest();
            xhttpMenuImageConfig.open("GET", `${configData.data.menuimage.anonymoustoken}`, false);
            xhttpMenuImageConfig.setRequestHeader('Access-Control-Allow-Origin', '*');
            xhttpMenuImageConfig.send();
            let menuImageData = JSON.parse(xhttpMenuImageConfig.responseText);
            if(menuImageData && menuImageData.data && menuImageData.data.length > 0)
              menuImage = menuImageData.data
          } else
            menuImage = configData.data.menuimage.anonymoustoken
        }
        if(configData.data.externaluserregisterimage && configData.data.externaluserregisterimage.anonymoustoken && configData.data.externaluserregisterimage.anonymoustoken.length > 0) {
          if (configData.data.externaluserregisterimage && configData.data.externaluserregisterimage.filerepositorymethod && configData.data.externaluserregisterimage.filerepositorymethod == 2) {
            let xhttpExternalUserRegisterImageConfig = new XMLHttpRequest();
            xhttpExternalUserRegisterImageConfig.open("GET", `${configData.data.externaluserregisterimage.anonymoustoken}`, false);
            xhttpExternalUserRegisterImageConfig.setRequestHeader('Access-Control-Allow-Origin', '*');
            xhttpExternalUserRegisterImageConfig.send();
            let externalUserRegisterImageData = JSON.parse(xhttpExternalUserRegisterImageConfig.responseText);
            if(externalUserRegisterImageData && externalUserRegisterImageData.data && externalUserRegisterImageData.data.length > 0)
              externalUserRegisterImage = externalUserRegisterImageData.data
          } else
            externalUserRegisterImage = configData.data.externaluserregisterimage.anonymoustoken
        }

        PapiroConsole.log(configData.data.primarycolortheme && configData.data.primarycolortheme != null && configData.data.primarycolortheme != "")
        PapiroConsole.log(configData.data.primarycolortheme)
        PapiroConsole.log(primarycolorTheme)
      }

      const whitelabelsession = JSON.parse(localStorage.getItem("whitelabel"));
      if(whitelabelsession != null){
        if(whitelabelsession.primarycolortheme && whitelabelsession.primarycolortheme != null && whitelabelsession.primarycolortheme != "")
          primarycolorTheme = whitelabelsession.primarycolortheme;
        if(whitelabelsession.secondarycolortheme && whitelabelsession.secondarycolortheme != null && whitelabelsession.secondarycolortheme != "")
        secondarycolorTheme = whitelabelsession.secondarycolortheme;
        if(whitelabelsession.footertext && whitelabelsession.footertext != null && whitelabelsession.footertext != "")
          footerText = whitelabelsession.footertext
        if(whitelabelsession.applicationname && whitelabelsession.applicationname != null && whitelabelsession.applicationname != "")
          appName = whitelabelsession.applicationname
        if(whitelabelsession.logintitle && whitelabelsession.logintitle != null && whitelabelsession.logintitle != "")
          loginTitle = whitelabelsession.logintitle
        if(whitelabelsession.showfooterusetermslink != null)
          showfooterusetermslink = whitelabelsession.showfooterusetermslink
        if(whitelabelsession.loginimage && whitelabelsession.loginimage.anonymoustoken && whitelabelsession.loginimage.anonymoustoken.length > 0) {
          if (whitelabelsession.loginimage && whitelabelsession.loginimage.filerepositorymethod && whitelabelsession.loginimage.filerepositorymethod == 2) {
            let xhttpLoginImageConfig = new XMLHttpRequest();
            xhttpLoginImageConfig.open("GET", `${whitelabelsession.loginimage.anonymoustoken}`, false);
            xhttpLoginImageConfig.setRequestHeader('Access-Control-Allow-Origin', '*');
            xhttpLoginImageConfig.send();
            let loginImageData = JSON.parse(xhttpLoginImageConfig.responseText);
            if(loginImageData && loginImageData.data && loginImageData.data.length > 0)
              loginImage = loginImageData.data
          } else
            loginImage = whitelabelsession.loginimage.anonymoustoken
        }
        if(whitelabelsession.menuimage && whitelabelsession.menuimage.anonymoustoken && whitelabelsession.menuimage.anonymoustoken.length > 0) {
          if (whitelabelsession.menuimage && whitelabelsession.menuimage.filerepositorymethod && whitelabelsession.menuimage.filerepositorymethod == 2) {
            let xhttpMenuImageConfig = new XMLHttpRequest();
            xhttpMenuImageConfig.open("GET", `${whitelabelsession.menuimage.anonymoustoken}`, false);
            xhttpMenuImageConfig.setRequestHeader('Access-Control-Allow-Origin', '*');
            xhttpMenuImageConfig.send();
            let menuImageData = JSON.parse(xhttpMenuImageConfig.responseText);
            if(menuImageData && menuImageData.data && menuImageData.data.length > 0)
              menuImage = menuImageData.data
          } else
            menuImage = whitelabelsession.menuimage.anonymoustoken
        }
        if(whitelabelsession.externaluserregisterimage && whitelabelsession.externaluserregisterimage.anonymoustoken && whitelabelsession.externaluserregisterimage.anonymoustoken.length > 0) {
          if (whitelabelsession.externaluserregisterimage && whitelabelsession.externaluserregisterimage.filerepositorymethod && whitelabelsession.externaluserregisterimage.filerepositorymethod == 2) {
            let xhttpExternalUserRegisterImageConfig = new XMLHttpRequest();
            xhttpExternalUserRegisterImageConfig.open("GET", `${whitelabelsession.externaluserregisterimage.anonymoustoken}`, false);
            xhttpExternalUserRegisterImageConfig.setRequestHeader('Access-Control-Allow-Origin', '*');
            xhttpExternalUserRegisterImageConfig.send();
            let externalUserRegisterImageData = JSON.parse(xhttpExternalUserRegisterImageConfig.responseText);
            if(externalUserRegisterImageData && externalUserRegisterImageData.data && externalUserRegisterImageData.data.length > 0)
              externalUserRegisterImage = externalUserRegisterImageData.data
          } else
            externalUserRegisterImage = whitelabelsession.externaluserregisterimage.anonymoustoken
        }
      }

      Api.getSassHirerId = function () {
        return baseSassHirerId;
      }

      Api.getPrimaryColorTheme = function () {
        return primarycolorTheme;
      }
      
      Api.setPrimaryColorTheme = function (attribute) {
        primarycolorTheme = attribute;
      }

      Api.getSecondaryColorTheme = function () {
        return secondarycolorTheme;
      }
      
      Api.setSecondaryColorTheme = function (attribute) {
        secondarycolorTheme = attribute;
      }

      Api.getAppName = function () {
        return appName;
      }
      
      Api.setAppName = function (attribute) {
        appName = attribute;
      }

      Api.getFooterText = function () {
        return footerText;
      }
      
      Api.setFooterText = function (attribute) {
        footerText = attribute;
      }

      Api.getShowFooterUseTermsLink = function () {
        return showfooterusetermslink;
      }
      
      Api.setShowFooterUseTermsLink = function (attribute) {
        showfooterusetermslink = attribute;
      }
      
      Api.getLoginTitle = function () {
        return loginTitle;
      }
      
      Api.setLoginTitle = function (attribute) {
        loginTitle = attribute;
      }

      Api.getLoginImage = function () {
        return loginImage;
      }

      Api.setLoginImage = function (attribute) {
        loginImage = attribute;
      }

      Api.getMenuImage = function () {
        return menuImage;
      }

      Api.setMenuImage = function (attribute) {
        menuImage = attribute;
      }

      Api.getExternalUserRegisterImage = function () {
        return externalUserRegisterImage;
      }

      Api.setExternalUserRegisterImage = function (attribute) {
        externalUserRegisterImage = attribute;
      }

      if (token == null) {
      }

      Api.setHeaderAcceptLanguage = function (lang) {
        Api.defaults.headers.common['Accept-Language'] = lang
      }

      Api.applicationName = function () {
        return "Atendimento Inteligente ao Cliente";
      }

      Api.apiurl = function () {
      

        return baseURL;
      }
      Api.apiurlbase = function () {
      

      return baseURLBack;
      }

      Api.frontCalledServiceUrl = function () {
        return baseURLFrontCalledService;
      }

      Api.fronturl = function () {
        return urlfront;
      }

      Api.apiurlcone = function () {
        return baseURLCone;
      }

      Api.apiurlsignalrhub = function () {
        return baseURLApiSignalrHub;
      }

      Api.getProductsAvaliable = async function  (hirerid) {
        var ret = [];
        var Hirer = {
          Id : hirerid
        };
        var headers = {
          'Content-Type': 'application/json',
        };
      
      Api.resetToken = function () {
        token = ''
        Api.defaults.headers.common['Authorization'] = 'Bearer invalidtoken' + token;
      }

        await this.post("/users/avaliable/products", Hirer, {
          headers: headers
        })
          .then(async result => {
            if (result.data.success) {
              //alert(JSON.stringify(result));
              if(result && result.data && result.data.success){
                if(result.data.data){
                  ret = result.data.data;
                }
              }        
            
            }
          })
          .catch(err => {
            if(err.response && err.response.data && err.response.data.errors){
            }
            else{
            }
          });

        return ret;
      }

      Api.VertexIsAvaliable = async function (){
        var result = false;
        var data = await this.getProductsAvaliable();
        if(data.constructor === Array && data.length > 0){
          data.forEach(element => {
            if(element.id == 3){
              result=true;
            }
          });
        }
        return result;
      }

      Api.ToTalkIsAvailable = async function (){
        var result = false
        var data = await this.getProductsAvaliable()
        if(data.constructor === Array && data.length > 0){
          data.forEach(element => {
            if(element.id == 2)
              result = true
          })
        }
        return result
      }

      Api.ProductsAvaliable = async function () {
        return await this.getProductsAvaliable();
      }

      Api.logoff = function () {
        const whitelabelsession = JSON.parse(localStorage.getItem("whitelabel"))
        if(whitelabelsession != null){
          localStorage.removeItem('whitelabel');
          primarycolorTheme = "#3F51B4"
          secondarycolorTheme = "#277afe"
          appName = "CMTECH - Mexx 2DO"
          footerText = "CMTECH - Mexx 2DO"
          loginImage = null
          menuImage = null
          externalUserRegisterImage = null
          loginTitle = "Atendimento Inteligente ao Cliente"
          showfooterusetermslink = true
        }
        userSessionActions.removeUserSession();
		localStorage.clear();
        Api.get("/users/removeClaims")
          .then(result => {
        if(Api && Api.resetToken){Api.resetToken();}
            document.location.href = '/?sessão expirada';
          })
          .catch(err => {
        if(Api && Api.resetToken){Api.resetToken();}
            document.location.href = '/?sessão expirada';
        });
        
      }

      Api.kickoff = function (result){
      PapiroConsole.log("=== Api.kickoff ===");
      //console.error("=== Api.kickoff ===");
      //console.error(result);
        PapiroConsole.log(result);
        if((result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0])){
      
          if((result.response.data.errors[0].indexOf("invalid_token") > -1)){
            userSessionActions.removeUserSession();
            const whitelabelsession = JSON.parse(localStorage.getItem("whitelabel"))
            if(whitelabelsession != null){
              localStorage.removeItem('whitelabel');
              primarycolorTheme = "#3F51B4"
              secondarycolorTheme = "#277afe"
              appName = "CMTECH - Mexx 2DO"
              footerText = "CMTECH - Mexx 2DO"
              loginImage = null
              menuImage = null
              externalUserRegisterImage = null
              loginTitle = "Atendimento Inteligente ao Cliente"
              showfooterusetermslink = true
            }
			localStorage.clear();
			document.location.href='/?sessão expirada';
          }

          if((result.response.data.errors[0].indexOf("ContractInvalid") > -1)){
        
            document.location.href='/pt/my-plan?message';
          }
        } 
        if((result && result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0]) ){
      
          if( (result.data.response.data.errors[0].indexOf("invalid_token") > -1)){
            userSessionActions.removeUserSession();
            const whitelabelsession = JSON.parse(localStorage.getItem("whitelabel"))
            if(whitelabelsession != null){
              localStorage.removeItem('whitelabel');
              primarycolorTheme = "#3F51B4"
              secondarycolorTheme = "#277afe"
              appName = "CMTECH - Mexx 2DO"
              footerText = "CMTECH - Mexx 2DO"
              loginImage = null
              menuImage = null
              externalUserRegisterImage = null
              loginTitle = "Atendimento Inteligente ao Cliente"
              showfooterusetermslink = true
            }
			localStorage.clear();
			document.location.href='/?sessão expirada';
          }

          if( (result.data.response.data.errors[0].indexOf("ContractInvalid") > -1)){
          
            document.location.href='/pt/my-plan?message';
          }
        }
        
        if(result && result.errors && result.errors[0] ){
            if(result.errors[0] === "Error: invalid_token")
            {
              userSessionActions.removeUserSession();
              const whitelabelsession = JSON.parse(localStorage.getItem("whitelabel"))
              if(whitelabelsession != null){
                localStorage.removeItem('whitelabel');
                primarycolorTheme = "#3F51B4"
                secondarycolorTheme = "#277afe"
                appName = "CMTECH - Mexx 2DO"
                footerText = "CMTECH - Mexx 2DO"
                loginImage = null
                menuImage = null
                externalUserRegisterImage = null
                loginTitle = "Atendimento Inteligente ao Cliente"
                showfooterusetermslink = true
              }
			  localStorage.clear();
			  document.location.href='/?sessão expirada';
            }

            if(result.errors[0] === "Error: ContractInvalid")
            {
              document.location.href='/pt/my-plan?message';
            }
        }
        
      
        if(result && result.data && result.data.errors && result.data.errors[0])
        {
          if((result.data.errors[0] === "Error: invalid_token")){
            userSessionActions.removeUserSession();
            const whitelabelsession = JSON.parse(localStorage.getItem("whitelabel"))
            if(whitelabelsession != null){
              localStorage.removeItem('whitelabel');
              primarycolorTheme = "#3F51B4"
              secondarycolorTheme = "#277afe"
              appName = "CMTECH - Mexx 2DO"
              footerText = "CMTECH - Mexx 2DO"
              loginImage = null
              menuImage = null
              externalUserRegisterImage = null
              loginTitle = "Atendimento Inteligente ao Cliente"
              showfooterusetermslink = true
            }
			localStorage.clear();
			document.location.href='/?sessão expirada';
          }
          
          if((result.data.errors[0] === "Error: ContractInvalid")){
            document.location.href='/pt/my-plan?message';
          }
        }
      }

      Api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }else {
      Api = null
      baseURL = null
      baseURLBack = null
    }
  } else {
    Api = null
    baseURL = null
    baseURLBack = null
  }
} else {
  Api = null
  baseURL = null
  baseURLBack = null
}

export { baseURL, baseURLBack, baseURLCone, baseURLApiSignalrHub }
export default Api;

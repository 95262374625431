import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { Button, CircularProgress, Container, CssBaseline, Grid, IconButton, Link, Switch, TextField, createTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactFlagsSelect from "react-flags-select";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DialogTerm from '../../components/dialog-term';
import DialogTwoFactorAuth from '../../components/dialog-two-factor-auth';
import DialogUserLogged from '../../components/dialog-user-logged';
import LoginGoogle from '../../components/google/index';
import Logo from '../../components/logo/logo';
import CustomizedSnackbars from '../../components/material-snackbars';
import MELogo from '../../components/me-feedback-images/me-logo';
import Attendance from '../../images/me-feedback/attendance.jpg';
import * as lastCalledsQueueActions from '../../redux/actions/last-calleds-queue';
import * as userSessionActions from '../../redux/actions/user-session';
import Api from '../../services/api';
import { getLastCalledsQueueByUserId } from '../../services/called.service';
import { getCanCreateClientUsersExternal } from '../../services/config.service';
import { Overlay } from '../../styles/global';
import { Styles } from '../../styles/material-styles';
import { PapiroConsole } from '../../utils/papiroConsole';
import { resetLocalStorageVariables } from '../../utils/storageUtils';
import './styles.css';

import "../../../node_modules/@govbr-ds/webcomponents/dist/webcomponents.umd.min.js"
import { v4 as uuidv4 } from 'uuid';
const crypto = require('crypto');

const defaultProps = {
  borderColor: 'text.secondary',
  bgcolor: "text.secondary",
  color:"secondary.contrastText",
  m: 1,
  border: 1,
  style: { width: '15rem', height: '15rem' },
};


class Login extends Component {
  constructor(props) {
    super(props);

    let langCode = 'BR'
    let language = window.location.pathname.split('/')[1]
    if (language === 'en')
      langCode = 'US'
    else if (language === 'es')
      langCode = 'ES'
    else
      langCode = 'BR'

    this.state = {
      signInForm: {
        email: "",
        password: "",
        twoFactorAuthCode: ""
      },
      rememberMe:false,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      debug:false,
      openDialogUserLogged: false,
      openDialogTerm: false,
      openDialogTwoFactorAuth: false,
      twoFactorAuthType: "",
      userAcceptedLogged: false,
      languageCode: langCode,
      cancreateclientusersexternal: false,
      showknowledgebaseinlogin: false,
      hirerid: 0,
      colorTheme: Api != null ? Api.getPrimaryColorTheme() : "#3F51B4",
      appName: Api != null ? Api.getAppName() : "CMTECH - Mexx 2DO",
      loginTitle: Api != null ? Api.getLoginTitle() : "Atendimento Inteligente ao Cliente",
      secondaryColorTheme: Api != null ? Api.getSecondaryColorTheme() : "#277afe",
      logo: Api != null ? Api.getLoginImage() : null,
    };

    PapiroConsole.log(Api != null ? Api.getPrimaryColorTheme() : "#3F51B4")
    
    this.headers = {
      'Content-Type': 'application/json',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.toggleRememberMe = this.toggleRememberMe.bind(this);
    this.rememberUser = this.rememberUser.bind(this);
    this.getRememberedUser = this.getRememberedUser.bind(this);
    this.forgetUser = this.forgetUser.bind(this);

    this.loginGoogleSuccess = this.loginGoogleSuccess.bind(this);
    this.loginGoogleError = this.loginGoogleError.bind(this);
    this.logoutGoogle = this.logoutGoogle.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
    this.getCanCreateClientUsersExternal = this.getCanCreateClientUsersExternal.bind(this)
    this.goToCreateExternalUserPage = this.goToCreateExternalUserPage.bind(this)
    this.goToMoreInformation = this.goToMoreInformation.bind(this)
    this.goToQuestionsInformation = this.goToQuestionsInformation.bind(this)
    this.goToLoginGovBr = this.goToLoginGovBr.bind(this)
  }
  
  async componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

    async componentDidMount() {
      document.title = this.state.appName;

      if(Api == null) {
        var l = this.props.match.params.lang;
        document.location.href=`/${l}/unavailable`
      }

      localStorage.removeItem('@user-client-session-2do-logged');
      localStorage.removeItem('accesstoken');
      localStorage.removeItem('accesstokenreports');
      localStorage.removeItem('accesstoken2talk');

      this.props.removeUserSession()

      if((document.location.href.indexOf("localhost") > -1) 
      || (document.location.href.indexOf("demo.cube") > -1)  
      || (document.location.href.indexOf("192.168.1.168") > -1)  
      || (document.location.href.indexOf("127.0.0.1") > -1)  
      || (document.location.href.indexOf("192.168.") > -1)  
      ){
        this.setState({ debug: true });
      }
      const userlogin = await this.getRememberedUser();
      const chatContext = this.context;

       this.setState(prevState => ({
        signInForm: {
          ...prevState.signInForm,
          'email': userlogin ? userlogin :  ""
        },
        rememberMe: userlogin ? true : false
      }));
      await this.getCanCreateClientUsersExternal()
  }

  async getCanCreateClientUsersExternal() {
    let result = await getCanCreateClientUsersExternal()
    if (result && result.success && result.data != null) {
      if (result.data != null) {
        this.setState({
          cancreateclientusersexternal: result.data.cancreateclientusersexternal,
          showknowledgebaseinlogin: result.data.showknowledgebaseinlogin,
          hirerid: result.data.hirerid
        })
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      })
      Api.kickoff(result)
    }
  }

  async getTutorial(){
//endpoint antigo que pegava as notificações pro usuário, provavelmente vai ser descontinuado


    /*
    Api.get(`/notification/tutorial`)
      .then(result => {
        if (result.data.success) {
          let listNotification = result.data.data;
          let count = 0;
          let countNot = 0;
          let redirection = true;
          let l = this.props.match.params.lang;
          listNotification.map((item) => {
            item.notification.map((itemDois) => {
              if(itemDois.required == true && itemDois.finish == false){
                count++;
              }
              if(itemDois.required == true){
                countNot++;
              }
            });
            if(countNot == count){
              //document.location.href=`/${l}/steps`
              return item.id;
            }
            count = 0;
            countNot = 0;
            return 0;
          });
        }
    });*/
    
  }

  openDialogTerm = () => {
    this.setState({openDialogTerm: !this.state.openDialogTerm });
  }

  openDialogTwoFactorAuth = () => {
    this.setState({ openDialogTwoFactorAuth: !this.state.openDialogTwoFactorAuth });
  }

  openDialogUserLogged = () => {
    this.setState({openDialogUserLogged: !this.state.openDialogUserLogged });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      signInForm: {
        ...prevState.signInForm,
        [name]: value
      }
    }));
  }

  showProblem = () => {

  }

  handleSubmit = async e => {
    if(e && e.preventDefault){
      e.preventDefault();
    }
    
    this.setState({ loading: true });
    this.props.addUserSession(null);

    const { email, password, twoFactorAuthCode } = this.state.signInForm;

    Api.post("/users/login", 
      { email, password, useracceptedlogged: this.state.userAcceptedLogged, ismobile: false, twoFactorAuthCode }, 
      { headers: this.headers }
      )
      .then(async result => {

        PapiroConsole.log("entrei no login aqui ")
        if (result.data.success) {
          resetLocalStorageVariables()
          this.props.clearlastCalledsQueue()
          localStorage.setItem('userid', result.data.data.id);
          if(result.data.data.firstaccess == false && result.data.data.system == true)
          {
            if(result.data.data.userrole && result.data.data.userrole.length > 0 ){
              this.props.addUserSession(result.data.data);
              this.props.history.push({
                pathname: `/pt/adm-password-reset`,
                state: {
                    user : result.data.data
                }
              });
            }
          } else {

            localStorage.setItem("refreshTokenTime", "0");
            this.props.addUserSession(result.data.data);
    
            localStorage.setItem('accesstoken', result.data.data.accesstoken);
            localStorage.setItem('accesstokenreports', result.data.data.accesstokenreports);
            localStorage.setItem('accesstoken2talk', result.data.data.accesstoken2talk);
            localStorage.setItem('reload', true);

            
            const rolesUser = result.data.data?.userrole ?? [];

            rolesUser.forEach(obj => {
              if (obj.role && obj.role.id === 4) {
                localStorage.setItem('@user-client-session-2do-logged', JSON.stringify({
                  name: result.data.data?.name,
                  email: result.data.data?.email
                }));
                return;
              }
            });
                     
      
            var l = this.props.match.params.lang;
            if(result.data.data.orgid != 0 && result.data.data.orgid != null){
            }else{
              if(result.data.data.startpage && result.data.data.startpage.length > 0 && result.data.data.startpage != 'undefined' && result.data.data.startpage != 'null') {
                document.location.href=`/${l}/${result.data.data.startpage}`
              } else {
                document.location.href=`/${l}/calleds`
              }
            }

          }
          //document.location.href="/calleds";
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        if(err.response && err.response.data && err.response.data.errors){
          if(err.response.data.errors && err.response.data.errors[0] == "Error: ChangePassword"){
            
            this.setState({
              loading: false,
              openNotification: true,
              notificationMessage: this.props.intl.formatMessage({id:"passwordpolicy.passwordexpirationtimewarning"})
            });
          }else if(err.response.data.errors && err.response.data.errors[0] == "Error: TermAccepted"){
            this.setState({
              loading: false,
              openDialogTerm: true
            });
          } else if (err.response.data.errors && err.response.data.errors[0] == "Error: UserLogged") {
            this.setState({
              loading: false,
              openDialogUserLogged: true
            });
          } else if (err.response.data.errors && err.response.data.errors[0] == "Error: TwoFactorAuthenticationEnableEmail") {
              this.setState({
                loading: false,
                openDialogTwoFactorAuth: true,
                twoFactorAuthType: "email"
              });
          } else if (err.response.data.errors && err.response.data.errors[0] == "Error: TwoFactorAuthenticationEnable") {
            this.setState({
              loading: false,
              openDialogTwoFactorAuth: true,
              twoFactorAuthType: "app"
            });
          } else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          
        }
        else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: this.props.intl.formatMessage({id:"process.error"})
          });

        }
      });
  };

  getLastCalledsQueueByUserId = async userId => {
    this.props.clearlastCalledsQueue()
    let response = await getLastCalledsQueueByUserId(userId)
    if (response != null && response.success != null && response.success && response.data != null) {
      if (Array.isArray(response.data)) {
        response.data.forEach(item => {
          if (item.id != null && item.title != null)
            this.props.equeuelastCalledsQueue({ id: item.id, title: item.title })
        })
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: false,
        notificationVariant: 'error',
        notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    })
      Api.kickoff(response)
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }
  
  toggleRememberMe = (event,value) => {
    this.setState({ rememberMe: value })
      if (value === true) {
    //user wants to be remembered.
      this.rememberUser();
    } else {
      this.forgetUser();
    }
  }

  rememberUser = async () => {
        try {
          await localStorage.setItem('field-service-cmetch', this.state.signInForm.email);
        } catch (error) {
          // Error saving data
        }
  };
  getRememberedUser = async () => {
        try {
          const userlogin = await localStorage.getItem('field-service-cmetch');
          if (userlogin !== null) {
            // We have username!!
            return userlogin;
          }
        } catch (error) {
          // Error retrieving data
        }
  };
  forgetUser = async () => {
      try {
        await localStorage.removeItem('field-service-cmetch');
      } catch (error) {
       // Error removing
      }
  };
  loginGoogleSuccess = async (u) => {
   
    this.setState({ loading: true });

    const  email = u.ct.Mt;
    const token = u.tokenObj.access_token;
    Api.post("/users/loginOAuth", { email , token }, {
      headers: this.headers
    })
      .then(async result => {
        if (result.data.success) {
          
          localStorage.setItem("refreshTokenTime", "0");

          

          //result.data.data.language=this.props.match.url;

          this.props.addUserSession(result.data.data);
          //await this.getLastCalledsQueueByUserId(result.data.data.id)
          this.props.clearlastCalledsQueue()
          localStorage.setItem('accesstoken', result.data.data.accesstoken);
          localStorage.setItem('reload', true);
      
          var globalAdm = false
          var l = this.props.match.params.lang;
          if(result.data.data.orgid != 0 && result.data.data.orgid != null){
          }else{
            document.location.href=`/${l}/calleds`
          }

          if(result.data.data.firstaccess == false && result.data.data.system == true)
          {
            
            if(result.data.data.userrole && result.data.data.userrole.length > 0 ){
              


              var userRole = result.data.data.userrole

              userRole.forEach(element => {
                  if(element.role.id == 1){

                    globalAdm = true

                  }
              });

              if(globalAdm == true){
                document.location.href=`/${l}/adm-password-reset`
              }

            }

            
          }
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        if(err.response && err.response.data && err.response.data.errors){
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          });
        }
        else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationMessage: this.props.intl.formatMessage({id:"process.error"})
          });

        }
      });

  };
  loginGoogleError = async (u) => {
    const intl = this.props.intl;
    try {
      this.setState({
        loading: false,
        notificationVariant: "error",
        openNotification: true,
        notificationMessage: this.props.intl.formatMessage({id:"login.google.auth.fail"})
      });
      
    } catch (error) {
      // Error removing
    }
  };
  logoutGoogle = async (u) => {
    const intl = this.props.intl;
    try {
      this.setState({
        loading: false,
        notificationVariant: "info",
        openNotification: true,
        notificationMessage: this.props.intl.formatMessage({id:"login.logout.success"})
      });
    } catch (error) {
     // Error removing
    }
  };
  
  saveTermAndContinue = async (e) => {

    this.setState({
      loading: true,
      openDialogTerm: false
    });

    const { email } = this.state.signInForm;
    const applicationid = 1;

    Api.post("/users/saveUseTerm", { email, applicationid }, {
      headers: this.headers
    }).then(result => {
      if (result.data.success) {
        this.handleSubmit(e)
      }
    }).catch(err => {
      const intl = this.props.intl;
      if(err.response && err.response.data && err.response.data.errors){
        this.setState({
          loading: false,
          openNotification: true,
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
      }
      else{
        this.setState({
          loading: false,
          openNotification: true,
          notificationMessage: this.props.intl.formatMessage({id:"process.error"})
        });

      }
    });

  }

  saveUserLoggedAndContinue = async (e) => {

    await this.setState({
      loading: true,
      openDialogUserLogged: false,
      userAcceptedLogged: true
    });

    this.handleSubmit(e)

  }

  twoFactorAuthSubmit = async (e) => {

    await this.setState({
      loading: true,
      dialogTwoFactorAuth: false
    });

    this.handleSubmit(e)

  }

  setMessageUseTerm = () => {
    const intl = this.props.intl;
    this.setState({
      notificationVariant: "error",
      openNotification: true,
      notificationMessage: this.props.intl.formatMessage({id:"login.accept.terms"})
    });
  }

  selectLanguage = async (code) => {
  
    await this.setState({ languageCode: code })
    let urlCode = 'pt'
    let headerCode = 'pt-BR'
    if (code === "US") {
      urlCode = 'en'
      headerCode = 'en-US'
    } else if (code === "ES") {
      urlCode = 'es'
      headerCode = 'es-ES'
    }

    Api.setHeaderAcceptLanguage(headerCode)
    this.props.history.push(`/${urlCode}/`);
    window.location.reload()
  }

  goToCreateExternalUserPage = () => {
    this.props.history.push({
      pathname: `/pt/external-user-registration`,
      state: {
        hirerid : this.state.hirerid
      }
    });
  }
  dec2hex(dec) {
    return ("0" + dec.toString(16)).substr(-2);
  }
  
  generateCodeVerifier() {
    var array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, this.dec2hex).join("");
  }
   sha256(plain) {
    // returns promise ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest("SHA-256", data);
  }
  
   base64urlencode(a) {
    var str = "";
    var bytes = new Uint8Array(a);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }
  
  async  generateCodeChallengeFromVerifier(v) {
    var hashed = await this.sha256(v);
    var base64encoded = this.base64urlencode(hashed);
    return base64encoded;
  }
   goToLoginGovBr = async () => {
    let nonce = uuidv4();
    localStorage.setItem('nonce', `${nonce}`)
    let state = uuidv4();
    localStorage.setItem('state', `${state}`)
    let codeverifier = this.generateCodeVerifier();
    alert(codeverifier);
    localStorage.setItem('codeverifier', `${codeverifier}`)
    let code_challenge = await this.generateCodeChallengeFromVerifier(codeverifier);
    localStorage.setItem('code_challenge', `${code_challenge}`)
    const hash = crypto.createHash('sha256').update('test').digest('base64');
    let redirecturi = 'https://www.gov.br%2Fcdn%2Fgovbrportal%2Fsrc%2Fhtml%2Fredirection-endpoint.html';
    redirecturi='https%3A%2F%2Fportaldeservicos.gestao.gov.br%2Fpt%2Fcalleds';
    var baseurl='https://sso.acesso.gov.br/authorize';
    //baseurl='https://sso.staging.acesso.gov.br/authorize';
    let pathname = `${baseurl}?response_type=code&client_id=www.gov.br&scope=openid+email+profile+govbr_confiabilidades&redirect_uri=${redirecturi}&nonce=${nonce}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=S256`;
    console.log(pathname);
    alert(pathname);

    document.location.href=pathname;
    //this.props.history.push({ pathname: pathname, state: { } });
  }

  goToQuestionsInformation = () => {
    window.open('https://www.gov.br/gestao/pt-br/assuntos/central-de-atendimento/faq-da-central-de-atendimento-dos-sistemas-estruturantes-do-mgi', '_blank')
  }

  goToMoreInformation = () => {
    window.open('https://www.gov.br/gestao/pt-br/assuntos/central-de-atendimento', '_blank')
  }

  render() {
    const { classes, intl } = this.props;
    const { signInForm } = this.state;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    const theme = createTheme({
      palette: {
        primary: {
          main: this.state.colorTheme
        },
        secondary: {
          main: this.state.secondaryColorTheme
        }
      }
    })
    if (Api != null && (Api.getSassHirerId() == 1011 || Api.getSassHirerId() == 15) || (document.location.host.indexOf("localhost") > -1) || (document.location.host.indexOf("192.168.0.") > -1)) {
    return (
        <ThemeProvider theme={theme}>
         <form className={classes.form} onSubmit={this.handleSubmit}>

          <div style={{ background: "white", height: '100%', paddingTop: '1%' }}>
            <Grid container spacing={1} >
              <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
              <Grid item xs={12} sm={12} md={10} lg={10} xl={10} className="me-main-container-grid">
                <div className="me-wallpaper-block" style={{ backgroundImage: `url(${Attendance})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                  <MELogo style={{ width: '60%' }} />
                </div>
                <div className="me-form-block" >
                  <Grid container spacing={1} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: '#4F59A1' }} >Central de Atendimento aos<br/>Sistemas Estruturantes</h2>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center', color: '#8D8D8D' }} >
                      <p>Bem-vindo(a) ao <strong>Portal da Central de Atendimento</strong> aos sistemas estruturantes do Ministério da Gestão e da Inovação em Serviços Públicos.</p>
                      <br/>
                      <p>Acesse com o seu e-mail e senha ou cadastre-se para abrir um chamado.</p>
                      <p>Você receberá notificações por e-mail para acompanhar o atendimento.</p>
                      <p>Caso você não encontre, verifique o lixo eletrônico ou a notificação de quarentena "AntiSpam".</p>
                      <br/>
                      <p>Evite abrir um novo chamado sobre o mesmo assunto.</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: '#FFF', padding: '8%', borderRadius: 25, marginTop: '5%' }} >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={intl.formatMessage({ id: "email" })}
                            name="email"
                            autoComplete="email"
                            type="email"
                            autoFocus
                            value={signInForm.email}
                            onChange={this.handleInputChange} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={intl.formatMessage({ id: "password" })}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            inputProps={{ maxLength: 32, minLength: 6 }}
                            value={signInForm.password}
                            onChange={this.handleInputChange} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }}>
                          <Switch
                            value={this.state.rememberMe}
                            checked={this.state.rememberMe}
                            onChange={this.toggleRememberMe} /><FormattedMessage id="remember.me" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit} >
                          <FormattedMessage id="log.in" />
                        </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ paddingTop: 0 }}>
                          <Link href="/pt/forgot-password/" variant="body2">
                            <FormattedMessage id="forgot.password" />
                          </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ paddingTop: 0 }}>
                          <Link onClick={() => this.goToCreateExternalUserPage()} variant="body2" style={{ cursor: 'pointer' }}>
                            <FormattedMessage id="dont.have.access.to.system" />
                          </Link>
                        </Grid>
                        {/*
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ paddingTop: 0 }}>
                        <br-sign-in
                            type="primary"
                            density="middle"
                            label="Entrar com"
                            entity="gov.br"
                            onClick={() => this.goToLoginGovBr()} 
                          ></br-sign-in>                        

                        </Grid>
                        */}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
              <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
              <Grid item xs={12} sm={12} md={10} lg={10} xl={10} style={{ display: 'flex', paddingTop: '1%' }}>
                <div style={{ width: '45%', display: 'flex', justifyContent: 'center' }} className="me-form-more-buttons-container" >
                  <Button  onClick={() => this.goToQuestionsInformation()} 
                    variant="contained"
                    style={{ backgroundColor: '#EFEFEF', color: '#4C5180', fontWeight: 'bold', textTransform: 'none', borderRadius: 50 }} >
                    Perguntas Frequentes
                  </Button>
                </div>
                <div style={{ width: '55%', display: 'flex', justifyContent: 'center' }} className="me-form-more-buttons-container" >
                  <Button  onClick={() => this.goToMoreInformation()} 
                    variant="contained"
                    style={{ backgroundColor: '#EFEFEF', color: '#4C5180', fontWeight: 'bold', textTransform: 'none', borderRadius: 50 }} >
                    Acesse para mais informações
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
            </Grid>
         
          {this.state.openDialogTerm && (
            <DialogTerm
              handleConfirmation={e => this.saveTermAndContinue(e)}
              classes={classes}
              openDialogTermFunc={this.openDialogTerm}
              openDialogTerm={this.state.openDialogTerm}
              setMessageUseTerm={this.setMessageUseTerm} />
          )}
  
          {this.state.openDialogTwoFactorAuth && (
            <DialogTwoFactorAuth
              twoFactorAuthSubmit={e => this.twoFactorAuthSubmit(e)}
              classes={classes}
              openDialogFunc={this.openDialogTwoFactorAuth}
              openDialog={this.state.openDialogTwoFactorAuth}
              handleInputChange={this.handleInputChange}
              twoFactorAuthCode={this.state.signInForm.twoFactorAuthCode}
              twoFactorAuthType={this.state.twoFactorAuthType} />
          )}
  
          {this.state.openDialogUserLogged && (
            <DialogUserLogged
              handleConfirmation={e => this.saveUserLoggedAndContinue(e)}
              openDialogUserLoggedFunc={this.openDialogUserLogged} />
          )}
           </div>
        </form>
          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}
        
          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification} />
        </ThemeProvider>
      )
    } else {
      return (
        <ThemeProvider theme={theme}>
          <div style={{ background: "white", height: '100%' }}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className='reactFlagsSelectCustom'>
            <ReactFlagsSelect
              style={{ color: 'white', position: 'fixed', top: '3%', right: '3%' }}
              countries={["BR", "US", "ES"]}
              customLabels={{ BR: "PT-BR", US: "EN-US", ES: "ES" }}
              placeholder={intl.formatMessage({ id: "select.language" })}
              onSelect={this.selectLanguage}
              selected={this.state.languageCode} />
          </div>
          {this.state.showknowledgebaseinlogin && <div class="loginFooterKnowLedgeBaseFloatingButton">
            <Tooltip title={<h5>Acessar base de conhecimento</h5>}>
              <IconButton aria-label="knowledge-base"
                onClick={() => { this.props.history.push(`/${this.props.match.params.lang}/knowledgebases-external`) }}
                style={{
                  marginBottom: '30px',
                  backgroundColor: '#277afe',
                  color: '#FFF'
                }}>
                <EmojiObjectsIcon style={{ fontSize: '2rem' }} />
              </IconButton>
            </Tooltip>
          </div>}
          <div className={classes.paper}>
          
              <Logo src={this.state.logo} key="groups-fieldservice-awrar6aw15" />
            <div dangerouslySetInnerHTML={{ __html: this.state.loginTitle }} style={ this.state.loginTitle.length > 34 ? { fontSize: '1.1rem', textAlign: 'justify' } : { fontSize: '1.5rem', textAlign: 'justify' }}/>
            {/*<Typography component="h1" variant="h5" style={ this.state.loginTitle.length > 34 ? { fontSize: '1.1rem' } : { fontSize: '1.5rem' }}>
              {this.state.loginTitle}
            </Typography>*/}
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={intl.formatMessage({ id: "email" })}
                name="email"
                autoComplete="email"
                type="email"
                autoFocus
                value={signInForm.email}
                onChange={this.handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={intl.formatMessage({ id: "password" })}
                type="password"
                id="password"
                autoComplete="current-password"
                inputProps={{ maxLength: 32, minLength: 6 }}
                value={signInForm.password}
                onChange={this.handleInputChange}
              />
  
              <Switch
                value={this.state.rememberMe}
                checked={this.state.rememberMe}
                onChange={this.toggleRememberMe}
                /><FormattedMessage id="remember.me" />
  
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                <FormattedMessage id="log.in" />
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/pt/forgot-password/" variant="body2">
                    <FormattedMessage id="forgot.password" />
                  </Link>
                </Grid>
                { this.state.cancreateclientusersexternal && (<Grid item xs style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                  <Link onClick={() => this.goToCreateExternalUserPage()} variant="body2" style={{ cursor: 'pointer' }}>
                    <FormattedMessage id="dont.have.access.to.system" />
                  </Link>
                </Grid>)}
              </Grid>
              {this.state.debug && (
              <Grid container>
                <Grid item xs>
                  <LoginGoogle success={this.loginGoogleSuccess} error={this.loginGoogleError} logout={this.logoutGoogle}/>
                </Grid>
              </Grid>
              )}
  
            {this.state.openDialogTerm && (
              <DialogTerm
                handleConfirmation={e => this.saveTermAndContinue(e)}
                classes={classes}
                openDialogTermFunc={this.openDialogTerm}
                openDialogTerm={this.state.openDialogTerm}
                setMessageUseTerm={this.setMessageUseTerm}
              />
            )}
  
            {this.state.openDialogTwoFactorAuth && (
              <DialogTwoFactorAuth
                twoFactorAuthSubmit={e => this.twoFactorAuthSubmit(e)}
                classes={classes}
                openDialogFunc={this.openDialogTwoFactorAuth}
                openDialog={this.state.openDialogTwoFactorAuth}
                handleInputChange={this.handleInputChange}
                twoFactorAuthCode={this.state.signInForm.twoFactorAuthCode}
                twoFactorAuthType={this.state.twoFactorAuthType}
              />
            )}
  
            {this.state.openDialogUserLogged && (
              <DialogUserLogged
                handleConfirmation={e => this.saveUserLoggedAndContinue(e)}
                openDialogUserLoggedFunc={this.openDialogUserLogged}
              />
            )}
            </form>
          </div>
          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}
  
          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />
        </Container>
        </div>
      </ThemeProvider>
      );
    }
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => 
    bindActionCreators(Object.assign({}, userSessionActions, lastCalledsQueueActions), dispatch)

export default injectIntl(connect(null, mapDispatchToProps)(withStyles(Styles)(Login)));

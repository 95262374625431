import { Button, CircularProgress, Grid, Switch, TextField, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import SimpleAutoComplete from "../../../../components/auto-complete/autocomplete.js";
import CustomizedSnackbars from "../../../../components/material-snackbars/index.js";
import { AbilityContext } from '../../../../config/ability-context';
import * as componentActions from '../../../../redux/actions/channels.js';
import Api from "../../../../services/api";
import Api2Talk from "../../../../services/api2talk";
import { Overlay } from "../../../../styles/global.js";
import { Styles } from '../../../../styles/material-styles';

import ReactQuill from "react-quill";

class ChannelSettingData extends Component {

  constructor(props) {
    super(props);

    const { userSession, channels } = this.props;

    this.globalAdm = false;
    this.isClient = true;

    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    if (!channels || (channels && !channels.channel)) {
      var lang = this.props.match.params.lang;
      document.location.href = `/${lang}/channels`
    }

    this.itemInitialState = {
      id: null,
      channelid: channels.channel.id,
      workhourid: null,
      allowtimechatmanagement: false,
      allowstopplaytimechatmanagement: false,
      allowautomaticmessagedistribution: false,
      allowparticipatechatanotherusers: false,
      maximumnumbersimultaneouschatsagent: null,
      allowautomaticcontrolinactivity: false,
      inactivitytimevalue: null,
      defaultenterqueuemessage: '',
      defaultstartingchatmessage: '',
      defaultfinishingchatmessage: '',
      defaultofflinechatmessage: '',
      defaultinactivityfinishchatmessage: '',
      defaultofflinesendername: '',
      categoryselectionmode: null,
      messageunregisteredclient: '',
      notifybeforeinactivate: false,
      notificationleadtime: null,
      inactivationwarningmessage: ''
    };

    this.state = {
      item: this.itemInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      autocompleteInputValue: '',
      workhours: [],
      categoryselectionmodes: []
    };

    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
      ],
    }

    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }


  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }

  requestNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }

  requestThrowError(err) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  }

  notificationMessage(message) {
    this.setState({
      loading: false,
      openNotification: true,
      notificationVariant: 'error',
      notificationMessage: message
    });
  }

  validateFieldsRequired() {
    const intl = this.props.intl;

    if (this.state.channel.Name == null || this.state.channel.Name.length == 0) {
      const message = intl.formatMessage({ id: "channel.missing.name" });
      this.notificationMessage(message);
      return false;
    }

    if (this.state.channel.MessageProviderId == null || this.state.channel.MessageProviderId.id === undefined) {
      const message = intl.formatMessage({ id: "channel.missing.message_provider" });
      this.notificationMessage(message);
      return false;
    }

    if (this.state.channel.TechinicalTeamId == null || this.state.channel.TechinicalTeamId.id === undefined) {
      const message = intl.formatMessage({ id: "channel.missing.techinical_team" });
      this.notificationMessage(message);
      return false;
    }

    return true;
  }

  handleValidations() {

    if (!this.state.item.categoryselectionmode) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: 'O campo "Modo de seleção de categoria" é obrigatório.'
      });
      return false
    }

    if (this.state.item.allowautomaticcontrolinactivity && (!this.state.item.inactivitytimevalue || this.state.item.inactivitytimevalue < 1))
    {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: 'O campo "Valor do tempo de inatividade em minutos" precisa ser maior que zero.'
      });
      return false
    }

    if (this.state.item.allowautomaticcontrolinactivity && this.state.item.defaultinactivityfinishchatmessage === "<p><br></p>")
    {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: 'O campo "Mensagem padrão de encerramento de chat por inatividade" é obrigatório.'
      });
      return false
    }

    if (this.state.item.notifybeforeinactivate && this.state.item.notificationleadtime <= 0)
    {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: 'O campo "Tempo de antecedência para notificação de inatividade" precisa ser maior que zero.'
      });
      return false
    }

    if (this.state.item.allowautomaticcontrolinactivity && this.state.item.notificationleadtime >= this.state.item.inactivitytimevalue)
      {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: 'O campo "Tempo de antecedência para notificação de inatividade" precisa ser menor que o tempo de inatividade.'
        });
        return false
      }

    if (this.state.item.notifybeforeinactivate && this.state.item.inactivationwarningmessage === "<p><br></p>")
    {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: 'O campo "Mensagem de aviso de inativação da conversa" é obrigatório.'
      });
      return false
    }

    return true;
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });

    let data = { ...this.state.item }
    data.workhourid = data.workhourid ? data.workhourid.id : null
    data.categoryselectionmode = data.categoryselectionmode?.id

    let validations = this.handleValidations()
    if (validations) {
      if (data.id == null) {
        Api2Talk.post(`/channelsetting`, data)
          .then(result => {
            if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: 'Registro incluído com sucesso'
              });
            } else {
              this.setState({ loading: false });
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
            }
          })
          .catch(err => {
            this.setState({ loading: false });
            this.fetchDataThrowError(err);
            Api.kickoff(err);
          });
      } else {
        Api2Talk.put(`/channelsetting`, data)
          .then(result => {
            if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: 'Registro atualizado com sucesso'
              });
            } else {
              this.setState({ loading: false });
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
            }
          })
          .catch(err => {
            this.setState({ loading: false });
            this.fetchDataThrowError(err);
            Api.kickoff(err);
          });
      }
    }


  }

  async fetchWorkHours() {
    this.props.setLoadingState(true);

    try {
      const response = await Api.get(`/workhours`);
      this.props.setLoadingState(false);

      if (response.data.success) {
        const workhourslist = response.data.data.map(item => ({ id: item.id, name: item.name }));
        this.setState({
          loading: false,
          workhours: workhourslist
        });
      } else {
        this.requestNotSuccess(response);
        Api.kickoff(response);
      }
    } catch (err) {
      this.props.setLoadingState(false);
      this.requestThrowError(err);
      Api.kickoff(err);
    }
  }

  async fetchCategorySelectionModes() {
    this.props.setLoadingState(true);

    try {
      const response = await Api2Talk.get(`/channelsetting/categoryselectionmode`);
      this.props.setLoadingState(false);

      if (response.data.success) {
        const categoryselectionmodeslist = response.data.data.map(item => ({ id: item.id, name: item.name }));
        this.setState({
          loading: false,
          categoryselectionmodes: categoryselectionmodeslist
        });
      } else {
        this.requestNotSuccess(response);
        Api.kickoff(response);
      }
    } catch (err) {
      this.props.setLoadingState(false);
      this.requestThrowError(err);
      Api.kickoff(err);
    }
  }

  async fetchChannelSettingByChannel() {
    this.props.setLoadingState(true);

    try {
      const response = await Api2Talk.get(`/channelsetting/getbychannel/${this.state.item.channelid}`);
      this.props.setLoadingState(false);

      if (response.data.success) {
        if (response.data.count === 1) {
          let _workhour = null;
          let _categorymode = null;

          if (response.data.data?.workhourid)
            _workhour = this.state.workhours.find((item) => item.id === response.data.data.workhourid);

          if (response.data.data?.categoryselectionmode)
            _categorymode = this.state.categoryselectionmodes.find((item) => item.id === response.data.data.categoryselectionmode);

          this.setState({
            item: {
              ...this.state.item,
              id: response.data.data.id,
              allowtimechatmanagement: response.data.data?.allowtimechatmanagement,
              allowstopplaytimechatmanagement: response.data.data?.allowstopplaytimechatmanagement,
              allowautomaticmessagedistribution: response.data.data?.allowautomaticmessagedistribution,
              allowparticipatechatanotherusers: response.data.data?.allowparticipatechatanotherusers,
              maximumnumbersimultaneouschatsagent: response.data.data?.maximumnumbersimultaneouschatsagent,
              allowautomaticcontrolinactivity: response.data.data?.allowautomaticcontrolinactivity,
              inactivitytimevalue: response.data.data?.inactivitytimevalue,
              defaultenterqueuemessage: response.data.data?.defaultenterqueuemessage,
              defaultstartingchatmessage: response.data.data?.defaultstartingchatmessage,
              defaultfinishingchatmessage: response.data.data?.defaultfinishingchatmessage,
              defaultofflinechatmessage: response.data.data?.defaultofflinechatmessage,
              defaultinactivityfinishchatmessage: response.data.data?.defaultinactivityfinishchatmessage,
              defaultofflinesendername: response.data.data?.defaultofflinesendername,
              messageunregisteredclient: response.data.data?.messageunregisteredclient ? response.data.data?.messageunregisteredclient : '',
              notifybeforeinactivate: response.data.data?.notifybeforeinactivate ? response.data.data?.notifybeforeinactivate : false,
              notificationleadtime: response.data.data?.notificationleadtime ? response.data.data?.notificationleadtime : null,
              inactivationwarningmessage: response.data.data?.inactivationwarningmessage ? response.data.data?.inactivationwarningmessage : '',
              workhourid: _workhour,
              categoryselectionmode: _categorymode
            },
            loading: false
          });
        } else {
          this.setState({
            loading: false
          });
        }
      } else {
        this.requestNotSuccess(response);
        Api.kickoff(response);
      }
    } catch (err) {
      this.props.setLoadingState(false);
      this.requestThrowError(err);
      Api.kickoff(err);
    }
  }

  handleChangeAutoComplete(stateName, value) {
    let data = value.id > 0 ? value : null;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: data
      }
    }));
  }

  handleChangeSwitch(event) {
    const stateName = event.target.name;
    const value = event.target.checked;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
  }

  handleChangeEditor(value, name) {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value || ""
      }
    }));
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async componentDidMount() {
    await this.fetchWorkHours();
    await this.fetchCategorySelectionModes();
    await this.fetchChannelSettingByChannel();
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item, selectedTechnicians, autocompleteInputValue } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div style={{ paddingTop: '10px' }}>
        <div>
          <form name='myForm' className={classes.form}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12}>
                <SimpleAutoComplete
                  name="workhourid"
                  label={intl.formatMessage({ id: "menu.workhours" })}
                  options={this.state.workhours}
                  stateName='workhourid'
                  changeSelect={(stateName, value) => {
                    this.handleChangeAutoComplete(stateName, value)
                  }}
                  selected={this.state.item.workhourid}
                  htmlOptions={true}

                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <SimpleAutoComplete
                  name="categoryselectionmode"
                  label={intl.formatMessage({ id: "categoryselectionmode" })}
                  options={this.state.categoryselectionmodes}
                  stateName='categoryselectionmode'
                  changeSelect={(stateName, value) => {
                    this.handleChangeAutoComplete(stateName, value)
                  }}
                  selected={this.state.item.categoryselectionmode}
                  htmlOptions={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label={intl.formatMessage({ id: "offline.message.sending.identifier" })}
                  inputProps={{ maxLength: 255 }}
                  autoComplete="fname"

                  variant="outlined"
                  name="defaultofflinesendername"
                  onChange={this.handleInputChange}
                  value={this.state.item.defaultofflinesendername}

                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  value={this.state.item.allowtimechatmanagement}
                  checked={this.state.item.allowtimechatmanagement}
                  name="allowtimechatmanagement"
                  onChange={(e) => this.handleChangeSwitch(e)}
                />
                <FormattedMessage id="allowtimechatmanagement" />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  value={this.state.item.allowstopplaytimechatmanagement}
                  checked={this.state.item.allowstopplaytimechatmanagement}
                  name="allowstopplaytimechatmanagement"
                  onChange={(e) => this.handleChangeSwitch(e)}
                />
                <FormattedMessage id="allowstopplaytimechatmanagement" />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  value={this.state.item.allowautomaticmessagedistribution}
                  checked={this.state.item.allowautomaticmessagedistribution}
                  name="allowautomaticmessagedistribution"
                  onChange={(e) => this.handleChangeSwitch(e)}
                />
                <FormattedMessage id="allowautomaticmessagedistribution" />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  value={this.state.item.allowparticipatechatanotherusers}
                  checked={this.state.item.allowparticipatechatanotherusers}
                  name="allowparticipatechatanotherusers"
                  onChange={(e) => this.handleChangeSwitch(e)}
                />
                <FormattedMessage id="allowparticipatechatanotherusers" />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  type="number"
                  inputProps={{ maxLength: 250 }}
                  fullWidth
                  label={intl.formatMessage({ id: "maximumnumbersimultaneouschatsagent" })}
                  autoComplete='fmaximumnumbersimultaneouschatsagent'
                  variant='outlined'
                  name='maximumnumbersimultaneouschatsagent'
                  onChange={this.handleInputChange}
                  value={item.maximumnumbersimultaneouschatsagent}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  value={this.state.item.allowautomaticcontrolinactivity}
                  checked={this.state.item.allowautomaticcontrolinactivity}
                  name="allowautomaticcontrolinactivity"
                  onChange={(e) => this.handleChangeSwitch(e)}
                />
                <FormattedMessage id="allowautomaticcontrolinactivity" />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  type="number"
                  inputProps={{ maxLength: 250 }}
                  fullWidth
                  label={intl.formatMessage({ id: "inactivitytimevalue" })}
                  autoComplete='finactivitytimevalue'
                  variant='outlined'
                  name='inactivitytimevalue'
                  onChange={this.handleInputChange}
                  value={item.inactivitytimevalue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography>
                  <FormattedMessage id="defaultinactivityfinishchatmessage" />
                </Typography>
                <ReactQuill
                  name="defaultinactivityfinishchatmessage"
                  value={item.defaultinactivityfinishchatmessage}
                  style={{ height: 150, marginBottom: 32 }}
                  modules={this.reactQuillModules}
                  formats={this.reactQuillFormats}
                  fullWidth
                  variant="outlined"
                  id="defaultinactivityfinishchatmessage"
                  onChange={(value) => this.handleChangeEditor(value, "defaultinactivityfinishchatmessage")}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  value={this.state.item.notifybeforeinactivate}
                  checked={this.state.item.notifybeforeinactivate}
                  name="notifybeforeinactivate"
                  onChange={(e) => this.handleChangeSwitch(e)}
                />
                <FormattedMessage id="label_notifybeforeinactivate" />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  type="number"
                  inputProps={{ maxLength: 250 }}
                  fullWidth
                  label={intl.formatMessage({ id: "label_notificationleadtime" })}
                  autoComplete='fnotificationleadtime'
                  variant='outlined'
                  name='notificationleadtime'
                  onChange={this.handleInputChange}
                  value={item.notificationleadtime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography>
                  <FormattedMessage id="label_inactivationwarningmessage" />
                </Typography>
                <ReactQuill
                  name="inactivationwarningmessage"
                  value={item.inactivationwarningmessage}
                  style={{ height: 150, marginBottom: 32 }}
                  modules={this.reactQuillModules}
                  formats={this.reactQuillFormats}
                  fullWidth
                  variant="outlined"
                  id="inactivationwarningmessage"
                  onChange={(value) => this.handleChangeEditor(value, "inactivationwarningmessage")}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography>
                  <FormattedMessage id="defaultenterqueuemessage" />
                </Typography>
                <ReactQuill
                  name="defaultenterqueuemessage"
                  value={item.defaultenterqueuemessage}
                  style={{ height: 150, marginBottom: 32 }}
                  modules={this.reactQuillModules}
                  formats={this.reactQuillFormats}
                  fullWidth
                  variant="outlined"
                  id="defaultenterqueuemessage"
                  onChange={(value) => this.handleChangeEditor(value, "defaultenterqueuemessage")}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography>
                  <FormattedMessage id="defaultstartingchatmessage" />
                </Typography>
                <ReactQuill
                  name="defaultstartingchatmessage"
                  value={item.defaultstartingchatmessage}
                  style={{ height: 150, marginBottom: 32 }}
                  modules={this.reactQuillModules}
                  formats={this.reactQuillFormats}
                  fullWidth
                  variant="outlined"
                  id="defaultstartingchatmessage"
                  onChange={(value) => this.handleChangeEditor(value, "defaultstartingchatmessage")}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography>
                  <FormattedMessage id="defaultfinishingchatmessage" />
                </Typography>
                <ReactQuill
                  name="defaultfinishingchatmessage"
                  value={item.defaultfinishingchatmessage}
                  style={{ height: 150, marginBottom: 32 }}
                  modules={this.reactQuillModules}
                  formats={this.reactQuillFormats}
                  fullWidth
                  variant="outlined"
                  id="defaultfinishingchatmessage"
                  onChange={(value) => this.handleChangeEditor(value, "defaultfinishingchatmessage")}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography>
                  <FormattedMessage id="defaultofflinechatmessage" />
                </Typography>
                <ReactQuill
                  name="defaultofflinechatmessage"
                  value={item.defaultofflinechatmessage}
                  style={{ height: 150, marginBottom: 32 }}
                  modules={this.reactQuillModules}
                  formats={this.reactQuillFormats}
                  fullWidth
                  variant="outlined"
                  id="defaultofflinechatmessage"
                  onChange={(value) => this.handleChangeEditor(value, "defaultofflinechatmessage")}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography>
                  <FormattedMessage id="messageunregisteredclient" />
                </Typography>
                <ReactQuill
                  name="messageunregisteredclient"
                  value={item.messageunregisteredclient}
                  style={{ height: 150, marginBottom: 32 }}
                  modules={this.reactQuillModules}
                  formats={this.reactQuillFormats}
                  fullWidth
                  variant="outlined"
                  id="messageunregisteredclient"
                  onChange={(value) => this.handleChangeEditor(value, "messageunregisteredclient")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      onClick={this.handleSubmit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </form>
        </div>


        {this.state.loading && (
          <Overlay>
            <CircularProgress color='secondary' />
          </Overlay>
        )}

        {
          this.state.openNotification && (
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          )
        }

      </div>
    )
  }
}

ChannelSettingData.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  channels: state.channels
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChannelSettingData)));

ChannelSettingData.contextType = AbilityContext;